<template>
  <g :fill="color" fill-rule="evenodd">
    <circle cx="9" cy="9" r="9" />
    <path
      fill="#FFFFFF"
      d="M18 8.994c0 4.967-4.03 8.994-9 8.994s-9-4.027-9-8.994S4.03 0 9 0s9 4.027 9 8.994z"
    />
    <path
      fill="#FFFFFF"
      fill-rule="nonzero"
      d="M7.808 10.244L5.85 8.287A1 1 0 1 0 4.436 9.7l2.571 2.57a1 1 0 0 0 1.307.093l5.143-3.855a1 1 0 1 0-1.2-1.6l-4.449 3.335z"
    />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
